<template>
  <div class="modal is-active" v-if="tempProductLinkup">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title"
          >Product Linkup - {{ isNewProduct ? "Create" : "Update" }}</span
        >
        <button class="delete" aria-label="close" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Product Name</label>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Text input"
              v-model="tempProductLinkup.Name"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Four Letter Code</label>
          <p class="is-size-7">
            Cannot edit a four letter code as this links to fulfilment. Please
            contact developers if you required to do this.
          </p>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="e.g ABCD"
              maxlength="4"
              :disabled="!isNewProduct"
              v-model="tempProductLinkup.FourLetterCode"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="fivecode">Five Letter Code</label>
          <p class="is-size-7">
            This is used to link up a title to a publisher, it ensures that the
            marketing that opt in or out of is correctly synced to dotmailer.
          </p>
          <div class="control">
            <input
              name="fivecode"
              class="input"
              type="text"
              placeholder="e.g ABCDE"
              maxlength="5"
              v-model="tempProductLinkup.FiveLetterCode"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="destination">Destination</label>
          <div class="control">
            <div class="select" name="destination">
              <select v-model="tempProductLinkup.BatchDestinationId">
                <option :value="null">PLEASE SELECT</option>
                <option v-for="d in destinations" :value="d.Id" :key="d.Id">{{
                  d.Name
                }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Contact Group</label>
          <div class="control">
            <div class="select">
              <select v-model="tempProductLinkup.ContactGroupId">
                <option :value="null">None Selected...</option>
                <option v-for="c in ContactGroups" :value="c.Id" :key="c.Id">
                  {{ c.Name }}
                </option>
              </select>
            </div>
            &nbsp; &nbsp;
            <button class="button is-primary" v-on:click="addContactGroup">
              Create New
            </button>
          </div>
        </div>

        <div class="box" v-if="tempProductLinkup.ContactGroupId">
          <contact-group-table
            :contact-group-id="tempProductLinkup.ContactGroupId"
            :to-update.sync="contactGroupTableToBeUpdated"
          ></contact-group-table
          >&nbsp;
          <div class="level" style="margin-top:10px;">
            <div class="level-left"></div>
            <!-- Right side -->
            <div class="level-right">
              <button
                class="button is-primary is-pulled-right level-item"
                v-if="tempProductLinkup.ContactGroupId !== null"
                v-on:click="
                  editContactGroupDialog(tempProductLinkup.ContactGroupId)
                "
              >
                Edit this Contact Group
              </button>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label" for="xmasValue">Xmas Value</label>
          <p class="is-size-7">
            This is the field sent as part of Xmas Orders. Often its a date or
            issue number or just a fixed string like "XmasOrder"
          </p>
          <div class="control">
            <input
              name="xmasValue"
              class="input"
              type="text"
              v-model="tempProductLinkup.XmasValue"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="custom">Custom</label>
          <p class="is-size-7">
            Extra information (in json) here which is used for things like
            storing subs house magazine codes or similar
          </p>
          <div class="control">
            <textarea
              name="custom"
              style="height:100px"
              class="input"
              type="text"
              v-model="tempProductLinkup.Custom"
            ></textarea>
          </div>
          <p>
            Is valid custom data:
            <span v-if="customIsValidJson" class="tag is-success cur">Yes</span>
            <span v-else class="tag is-danger cur">No</span>
          </p>
        </div>

        <div class="field">
          <label class="label">Enabled</label>
          <b-switch v-model="tempProductLinkup.Enabled"></b-switch>
        </div>

        <validation-errors :model-state="validationErrors"></validation-errors>
      </section>

      <footer class="modal-card-foot">
        <b-button
          class="is-success"
          :loading="isLoading"
          v-on:click="saveChanges"
          :disabled="!customIsValidJson"
          >Save</b-button
        >
        <button class="button" v-on:click="close">Cancel</button>
      </footer>
    </div>

    <contact-group-insert-or-update
      v-if="EditContactGroup"
      :contact-group="EditContactGroup"
      v-on:close="closeContactGroupDialog"
      v-on:add="addContactGroupSuccess"
      fixed-group-type="Product"
    ></contact-group-insert-or-update>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "prodctInsertOrUpdate",
  components: {
    validationErrors: () =>
      import("../../components/molecules/validationErrors.vue"),
    contactGroupInsertOrUpdate: () =>
      import("./contactGroupInsertOrUpdate.vue"),
    contactGroupTable: () => import("./contactGroupTable.vue"),
  },
  mixins: [baseMixin],
  props: {
    productLinkup: null,
    destinations: null,
  },
  data() {
    return {
      isLoading: false,
      tempProductLinkup: this.productLinkup,
      APIUrl: process.env.VUE_APP_APIURL,
      validationErrors: null,
      ContactGroups: null,
      EditContactGroup: null,
      contactGroupTableToBeUpdated: false,
    };
  },
  created() {
    this.loadContactGroups();

    // var url = this.apiUrlGetBatchJobById(this.batchJobId);
    // this.axios.get(url).then(response => {
    //   this.Fulfilment = response.data;
    // });
  },
  methods: {
    loadContactGroups() {
      this.axios
        .get(this.APIUrl + "/contact-group/basic?groupType=Product")
        .then((response) => {
          this.ContactGroups = response.data;
        });
    },
    close() {
      //emit close!
      this.$emit("close");
    },
    saveChanges() {
      var self = this;
      self.validationErrors = null;

      this.isLoading = true;
      var url = this.APIUrl + "/product/";
      //save changes
      this.axios
        .post(url, this.tempProductLinkup)
        .then((response) => {
          var isNew = this.tempProductLinkup.Id == null;

          this.tempProductLinkup = response.data;
          this.isLoading = false;

          //show toast!
          this.$toast.open(
            "Successfully " +
              (isNew ? "updated" : "created") +
              " <b>'" +
              this.tempProductLinkup.Name +
              "'</b>"
          );

          //emit changes back
          if (isNew) this.$emit("add", response.data);
          else this.$emit("close");
        })
        .catch(function(re) {
          self.isLoading = false;

          if (re.response.status === 400) {
            self.$toast.open({
              message:
                "Saving failed the validation check, please see details below",
              duration: 2500,
              type: "is-danger",
            });
            self.validationErrors = re.response.data;
          } else {
            self.catchError(re);
          }
        });
    },

    editContactGroupDialog(contactGroupId) {
      //load from the API
      this.isLoading = true;
      var url = this.APIUrl + "/contact-group/" + contactGroupId;
      this.axios.get(url).then((response) => {
        this.EditContactGroup = response.data;
        this.isLoading = false;
      });
      //
    },
    addContactGroup() {
      this.EditContactGroup = {
        Id: null,
        Name: "",
        GroupType: 1,
        Contacts: [],
      };
    },

    addContactGroupSuccess(contactGroup) {
      this.tempProductLinkup.ContactGroupId = contactGroup.Id;
      this.closeContactGroupDialog();
    },
    closeContactGroupDialog() {
      this.loadContactGroups(); //reload table
      this.contactGroupTableToBeUpdated = true;
      this.EditContactGroup = null;
    },
  },
  computed: {
    isNewProduct() {
      return this.tempProductLinkup.Id == null;
    },
    customIsValidJson() {
      if (
        this.tempProductLinkup.Custom &&
        this.tempProductLinkup.Custom.length > 0
      ) {
        try {
          JSON.parse(this.tempProductLinkup.Custom);
        } catch (e) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>
